<template>
    <div class="sms_template_list_add">
        <el-card>
            <el-page-header @back="goBack" style="margin-bottom: 20px;"></el-page-header>
            <div class="flex">
                <el-form :model="form" label-width="auto" style="flex:1">
                    <el-form-item label="项目名称">
                        <el-col :span="8">
                            <el-input placeholder="请输入项目名称" v-model="form.name"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="所属行业">
                        <div-checkbox
                            :max="3"
                            :list="config.business"
                            @change="form.subBusiness = ''"
                            v-model="form.business"
                        ></div-checkbox>
                    </el-form-item>
                    <el-form-item label="所属子行业" v-show="form.business">
                        <div-checkbox
                            :max="5"
                            :list="businessCategory"
                            labelKey="name"
                            valueKey="id"
                            v-model="form.subBusiness"
                        ></div-checkbox>
                    </el-form-item>
                    <el-form-item label="业务地区">
                        <region :val1.sync="form.province" :val2.sync="form.city"></region>
                    </el-form-item>
                    <el-form-item label="人员规模">
                        <el-select v-model="form.people" clearable>
                            <el-option
                                v-for="item in config.people"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目简介">
                        <el-col :span="12">
                            <el-input placeholder="请输入项目简介" type="textarea" :rows="8" v-model="form.desc" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="项目logo（选填）">
                        <up-img
                            colUrl="https://finance.yfchuhai.cn/api/Common/upload"
                            :action="{ action: 'project' }"
                            :banner.sync="form.logo"
                        ></up-img>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="add">{{ "保存" }}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <el-dialog :visible.sync="show" width="750px"> </el-dialog>
    </div>
</template>

<script>
import fuzzySelection from "@/components/fuzzySelection";

export default {
    components: {
        fuzzySelection,
    },
    data() {
        return {
            form: {
                name: "",
                business: "",
                remark: "",
                subBusiness: "",
            },
            config: {},
            businessCategory: [],
        };
    },
    watch: {
        "form.business"(val) {
            if (!val) {
                return;
            }
            this.getBusinessCategory(val);
        },
    },
    created() {
        this.id = this.$route.query.id;
        this.getForm();
        this.getConfigDictionaries();
        // this.getBusinessCategory();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },

        async getConfigDictionaries() {
            let { data } = await this.$http.get("/admin/Common/getConfigDictionaries?config=entrepreneur");
            this.config = data.data;
        },
        async getForm() {
            if (!this.id) return;
            let { data: res } = await this.$http.get("/admin/Finance/getProjectDetail", { params: { id: this.id } });
            if (res.errorCode == 200) {
                this.form = res.data;
            }
        },
        async getBusinessCategory(val) {
            let { data } = await this.$http.get("/admin/finance/getBusinessCategoryList", { params: { id: val } });
            this.businessCategory = data.data;
        },
        pleaseSelect() {},
        showEdit(row) {
            this.form.topId = row.id;
            this.form.topTitle = row.title;
        },
        async add() {
            var url = "/admin/Finance/addProject";
            if (this.id) {
                url = "/admin/Finance/editProject";
            }
            var { businessInfo, financingCurrent, financingHistory, financingMessage, ...req } = this.form;
            let { data: res } = await this.$http.post(url, req);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$router.go(-1);
            }
        },
        preview() {
            this.show = true;
        },
        select() {
            this.$refs.pop.init(this.form.topType, this.form.topId);
        },
    },
    computed: {
        contentArticle() {
            var length = this.form.content.length;
            return Math.ceil(length / 67);
        },
    },
};
</script>

<style lang="less" scoped>
.for {
    transform: scale(0.5);
    transform-origin: 0 0;
}
.tips {
    color: gray;
    span {
        color: red;
    }
}
</style>
